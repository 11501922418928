import { defineMessages } from "@vp/i18n-helper";

export const surfaceUpsellMessage = defineMessages({
    upsellMessageForBackSide: {
        id: "studio.components.Toast.upsellMessageForBackSide",
        defaultMessage: "Back design has been added for [[pricing]]",
        description: "This message is displayed when a backside design is added"
    },
    upsellMessageForBlankBackSide: {
        id: "studio.components.Toast.upsellMessageForBlankBackSide",
        defaultMessage: "Back design has been removed for [[pricing]]",
        description: "This message is displayed when a backside design is removed"
    },
    upsellMessageForDefault: {
        id: "studio.components.Toast.upsellMessageForDefault",
        defaultMessage: "Design has been added for [[pricing]]",
        description: "This message is displayed when a new side design is added"
    },
    upsellMessageForBlankDefault: {
        id: "studio.components.Toast.upsellMessageForBlankDefault",
        defaultMessage: "Design has been removed for [[pricing]]",
        description: "This message is displayed when a new side design is removed"
    }
});
