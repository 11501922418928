/**
 * This template metadata is to be used as a temporary marker that an item is an image awaiting Sherbert's generation of its embroidery variant
 */
export const EMBROIDERY_IMAGE_LOCK_METADATA = {
    locks: {
        edit: true
    },
    intent: "studioEmbroideryGeneration"
};

export const RESET_EMBROIDERY_IMAGE_LOCK_METADATA = {
    locks: {
        edit: undefined
    },
    intent: undefined
};

export const NR_SCOPE = "@interal/utils-assets";

export const MAX_SHARPEN_PIXEL_SIZE = 10000000; // Max 10 Megapixels
export const MIN_SHARPEN_PIXEL_SIZE = 80; // Minimum 80 pixel
