import { getUploadDimensions, waitForAssetUrlSuccess } from "./validateAssets";
import { getImageVariant } from "./getImageVariant";
import { getBackgroundRemovedUrls } from "./getBackgroundRemovedUrls";
import { getSharpenedUrls } from "./getSharpenedUrls";
import { VariantType, type VistaAsset, type VistaAssetStore } from "@design-stack-vista/vista-assets-sdk";
import type { ModifiedAssetInfo } from "../types";

export const getModifiedAsset = async (
    processesToApply: string[],
    assetStore: VistaAssetStore,
    authToken: string,
    originalSourceUrl?: string,
    asset?: VistaAsset
): Promise<ModifiedAssetInfo> => {
    let printUrl = asset ? asset.print.getUrl() : originalSourceUrl?.replace("original", "print");
    let previewUrl = asset ? asset.webPreview.getUrl() : originalSourceUrl?.replace("original", "preview");

    // now we apply all processes
    /* eslint-disable no-await-in-loop -- we can't Promise.all at the end of the loop */
    if (asset?.isDamAsset()) {
        await asset.presign();
        // as we apply processes the 'current' asset will be replaced
        let currentAsset: VistaAsset | Promise<VistaAsset> = asset;
        for (const process of processesToApply) {
            currentAsset = await currentAsset;
            await currentAsset.presign();

            switch (process) {
                case "backgroundRemove": {
                    currentAsset = await getImageVariant(currentAsset, VariantType.BackgroundRemoved, assetStore);
                    printUrl = currentAsset.print.getUrl();
                    previewUrl = currentAsset.webPreview.getUrl();
                    break;
                }
                case "sharpen": {
                    // we can use the sharpened url directly - the print url would be the same thing
                    printUrl = currentAsset.sharpened.getUrl();

                    // some optimization based on the original size of the asset
                    // knowing that sherbert uses a max of 2000px in the larger dimension
                    // and sharpen runs at 4x
                    const { height, width } = getUploadDimensions(asset);

                    if (height >= 1500 || width >= 1500) {
                        // if the original asset was larger than 1500px in either dimension, then just use it.
                        // The sharpened web preview won't be much better
                        previewUrl = currentAsset.webPreview.getUrl();
                        // update the currentAsset in case we have additional image processing to do
                        currentAsset = getImageVariant(currentAsset, VariantType.Sharpened, assetStore);
                    } else if (height <= 500 && width <= 500) {
                        // both dimensions were less than 500.  Sharpen only runs at 4x, so it won't be larger than 2000px in either dimension
                        // so the web preview of the asset doesn't do anything for us.  Just use the sharpened url.
                        previewUrl = currentAsset.sharpened.getUrl();
                        // update the currentAsset in case we have additional image processing to do
                        currentAsset = getImageVariant(currentAsset, VariantType.Sharpened, assetStore);
                    } else {
                        // if either dimension was greater than 500, but neither was greater than 1500
                        // then we'll retrieve the webPreview of the sharpened to get a higher quality image to show on the site
                        currentAsset = await getImageVariant(currentAsset, VariantType.Sharpened, assetStore);
                        previewUrl = currentAsset.webPreview.getUrl();
                    }

                    // we want to wait for the preview url to be available otherwise the canvas will just be blank until generation finishes
                    await waitForAssetUrlSuccess(previewUrl, "imageModification-webPreview");
                    break;
                }
                default:
                    throw new Error(
                        "Tried to apply unexpected process type. If you are creating a new process type, add handling for it."
                    );
            }
        }

        return { printUrl, previewUrl, modifiedAsset: await currentAsset };
    }
    if (originalSourceUrl) {
        let currentSourceUrl = originalSourceUrl;
        for (const process of processesToApply) {
            // handle legacy non-sherbert uploads
            switch (process) {
                case "backgroundRemove": {
                    const { print, preview } = await getBackgroundRemovedUrls({
                        originalUrl: currentSourceUrl,
                        accessToken: authToken,
                        vistaAssetStore: assetStore
                    });
                    currentSourceUrl = print;
                    printUrl = print;
                    previewUrl = preview;
                    break;
                }
                case "sharpen": {
                    const { print, preview } = await getSharpenedUrls({
                        originalUrl: currentSourceUrl,
                        accessToken: authToken,
                        vistaAssetStore: assetStore
                    });
                    currentSourceUrl = print;
                    printUrl = print;
                    previewUrl = preview;
                    break;
                }
                default:
                    throw new Error(
                        "Tried to apply unexpected process type. If you are creating a new process type, add handling for it."
                    );
            }
        }
    }
    /* eslint-enable no-await-in-loop */
    return { printUrl, previewUrl };
};
