import { UnitlessDimensions, getImageAsPromise } from "@design-stack-vista/utility-core";
import { AssetStatusType, VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { retry } from "@internal/utils-network";
import { isAssetMultiPage } from "./isAssetMultiPage";
import type {
    ImageMetadata,
    PreparedVistaAsset,
    VistaAssetIsProcessed,
    VistaAssetWithImageDimensionInfo,
    VistaAssetWithImageInfo,
    VistaAssetWithKnownFormat,
    VistaAssetWithoutError
} from "../types";

function assertAssetIsPrepared(asset: VistaAsset): asserts asset is PreparedVistaAsset {
    if (asset.status.type !== AssetStatusType.Prepared) {
        throw new Error("Asset not prepared");
    }
}

function assertAssetHasNoError(asset: VistaAsset): asserts asset is VistaAssetWithoutError {
    if (asset.data?.info.image && "isError" in asset.data.info.image && asset.data.info.image.isError) {
        // @ts-expect-error error exists on the object (or can) but the types are wrong
        throw new Error(asset.data.info.image.error || "Unknown Error");
    }

    // if calls to sherbert were successful, this value should have a timestamp for creation time
    if (asset?.data?.createdMs === 0) {
        throw new Error("Asset failed to sync with sherbert");
    }
}

function assertAssetHasImageInfo(asset: VistaAsset): asserts asset is VistaAssetWithImageInfo {
    if (!asset.data?.info?.image) {
        throw new Error("Asset ImageInfo not available");
    }
}

function assertAssetHasKnownFormat(asset: VistaAsset): asserts asset is VistaAssetWithKnownFormat {
    if (asset.data?.info?.image?.format === "unknown") {
        throw new Error("Asset has unknown format");
    }
}

function isImageDimensionMetadata(metadata: ImageMetadata): metadata is Extract<ImageMetadata, "width" | "height"> {
    return "width" in metadata && "height" in metadata;
}

function isAssetWithImageDimensionInfo(asset: VistaAsset): asset is VistaAssetWithImageDimensionInfo {
    return !!asset.data?.info.image && "width" in asset.data.info.image && "height" in asset.data.info.image;
}

export function isAssetProcessed(asset: VistaAsset): asset is VistaAssetIsProcessed {
    try {
        assertAssetIsPrepared(asset);
        assertAssetHasImageInfo(asset);
        return true;
    } catch (error) {
        return false;
    }
}

export function getUploadDimensions(upload: VistaAsset, pageNumber?: number): UnitlessDimensions {
    let width = upload.data?.properties.printPixelWidth as number;
    if (!isAssetMultiPage(upload) && !width) {
        width = isAssetWithImageDimensionInfo(upload) ? upload.data.info.image.width : 0;
    }

    let height = upload.data?.properties.printPixelHeight as number;
    if (!isAssetMultiPage(upload) && !height) {
        height = isAssetWithImageDimensionInfo(upload) ? upload.data.info.image.height : 0;
    }

    if (isAssetMultiPage(upload) && pageNumber) {
        const pageInfo = upload.data?.info?.image?.pagesInfo?.find(p => p.pageNumber === pageNumber);
        if (pageInfo) {
            ({ width, height } = pageInfo);
        }
    }

    return { width, height };
}

export async function waitForAssetUrlSuccess(url: string, retryName: string) {
    let response: Response;
    try {
        // 202 means the asset is still being generated.  That is the only time we'll retry.  Any other response indicates failure.
        response = await retry<Response>(
            additionalHeaders => fetch(url, { method: "HEAD", headers: additionalHeaders }),
            {
                retryCount: 5,
                interval: 100,
                maxInterval: 2500,
                name: retryName,
                retryWhenNoException: result => result.status === 202
            }
        );
    } catch (e) {
        throw new Error(`Asset webPreview Url Failure (retry): ${(e as Error).message}`);
    }
    // 400 or higher is an error
    // 202 means the asset is still processing but we aren't going to wait forever
    if (response && (response.status >= 400 || response.status === 202)) {
        let msg = `${response.status} unknown`;
        try {
            if (response.status === 401) {
                msg = "401 Unauthorized";
            } else if (response.status === 202) {
                msg = "202 Still Processing";
            }
            // the HEAD request we make does not contain any body to extract more information from so 'unknown' is the best we can do for generic error codes like 400
        } catch {
            /* empty */
        }
        throw new Error(`Asset webPreview Url Failure: ${msg}`);
    }
}

export async function validateAssetAndRetrieveDimensions(
    asset: VistaAsset,
    pageNumber: number = 1,
    maxRetries: number = 7
): Promise<UnitlessDimensions> {
    // Ensure the asset is in a state in which it is usable on a design
    await asset.prepare();
    assertAssetIsPrepared(asset);

    // client side image info may fail.  server side information may take a moment to show up
    if (
        !asset.data ||
        !asset.data.info.image ||
        (asset.data.info.image.format === "unknown" && asset.data.isLocallyProcessed) ||
        (asset.data.info.image.isError && asset.data.isLocallyProcessed)
    ) {
        // wait until image info has been processed.
        await retry<void>(
            async () => {
                assertAssetHasImageInfo(asset);
                assertAssetHasNoError(asset);
                assertAssetHasKnownFormat(asset);
            },
            {
                // The vista-assets-sdk polls every 50ms so we'll wait longer than that
                // pollAndSetAssetImageData in
                // https://gitlab.com/Cimpress-Technology/FileReview/sherbert/sdk/vista-assets-sdk/-/blob/staging/src/store/VistaAssetStore.ts
                retryCount: maxRetries,
                interval: 100,
                maxInterval: 2500,
                name: "validateAsset-knownFormat"
            }
        );
    }

    assertAssetHasImageInfo(asset);
    assertAssetHasNoError(asset);
    assertAssetHasKnownFormat(asset);

    try {
        // @ts-ignore this is private but can be set incorrectly
        if (asset.signature === "abc") {
            // @ts-ignore reset this so we can presign it correctly
            asset.setSignature(undefined);
        }
    } catch {
        /* empty */
    }

    // just make sure this is presigned
    await asset.presign();

    let dimensions: UnitlessDimensions;
    if (isAssetMultiPage(asset)) {
        const info = await asset.getPageImageInfo({ pageNo: pageNumber });
        if (!isImageDimensionMetadata(info)) {
            throw new Error(`Page in error for asset: ${info.error || "unknown"}`);
        }

        const { width, height } = info;
        dimensions = { width, height };
    } else {
        dimensions = getUploadDimensions(asset);
    }

    // This is to stop any bad documents from getting created if preview generation were to fail
    // ex. nonembedded fonts pdf
    await waitForAssetUrlSuccess(asset.webPreview.getUrl(), "validateAsset-webPreview");

    if (Number.isNaN(dimensions.height) || Number.isNaN(dimensions.width) || !dimensions.width || !dimensions.height) {
        try {
            const { naturalWidth, naturalHeight } = await getImageAsPromise(asset.webPreview.getUrl());
            if (naturalHeight && naturalWidth) {
                dimensions = { width: naturalWidth, height: naturalHeight };
            } else {
                throw new Error("Unable to retrieve valid height/width for asset using HTMLImage");
            }
        } catch {
            throw new Error("Unable to retrieve valid height/width for asset, HTMLImage threw");
        }
    }

    return dimensions;
}
