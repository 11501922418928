import type { AssetImageInfoEventDTO } from "@design-stack-ct/assets-sdk";
import type { AssetStatusType, VistaAsset, VistaAssetStore } from "@design-stack-vista/vista-assets-sdk";
import type { InteractiveDesignEngine, HistoryEvent } from "@design-stack-vista/interactive-design-engine-core";
import type { ItemState, ItemType } from "@design-stack-vista/cimdoc-state-manager";
import type { DclMetadataItem } from "@design-stack-vista/cdif-types";
import type { StudioMetadataItem } from "@internal/utils-custom-metadata";
import type { BaseTrackingClient } from "@internal/utils-tracking";

/**
 * @todo: Duplicated from "@shared/features/Validations". Should be moved to "@internal/feature-validation" by Strawhats
 */
enum Severity {
    ERROR = "error",
    WARNING = "warning"
}

export enum ImageResolutionStatus {
    ERROR = "error",
    WARNING = "warning",
    OK = "ok"
}

export interface ValidatorItemConfig {
    type: ItemType;
    filterFn?: (item: ItemState, designEngine?: InteractiveDesignEngine) => boolean;
    // TODO: make use in https://gitlab.com/Cimpress-Technology/artwork-design/lemonade-squad/lemonade-stand/-/issues/164
    preventSave?: boolean;
    severity?: Severity;
}

export interface ImageResolutionValidatorItemConfig extends Omit<ValidatorItemConfig, "type" | "severity"> {
    warningThreshold: number;
    errorThreshold: number;
    minimumPpi: number;
}

/**
 * Data needed for resolving an item after the related upload has finished (or failed)
 */
export interface InstantUploadData {
    /** The id of the {@link HistoryEvent} returned from whichever executed command created the item holding a temporary URL */
    eventId?: HistoryEvent["id"];
    /** The URL being used as a placeholder while the upload is being processed by sherbert. */
    temporaryUrl: string;
    /**
     * Original Url for single color
     * In case of (single-color) the asset promise holds the promise that will generate monochromized asset (This will go in overlays).
     * We need to store the OriginalSourceUrl of the original asset that customer uploaded.
     */
    originalSourceUrl?: string;
    /**
     * Image properties for single color.
     * For single-color we need to store the applied color as well in overlays, threshold & inverted properties
     * this will be undefined for other cases.
     */
    imageProperties?: {
        color: string;
        threshold: number;
        inverted: boolean;
    };
    /** Keep metadata when rolling back the command (if necessary) */
    keepOriginalMetadata?: boolean;
    /** Optional item id to signal that the upload was started as part of an image replacement */
    replacedImageId?: string;
    /**
     * By default we don't show any processing UI since we want instant uploads to be treated as normal images
     * In some cases (like embroidery) having the item be treated as processing may be preferred
     */
    showProcessing?: boolean;
    /** The asset being uploaded */
    assetPromise: Promise<VistaAsset | void>;
    /** Apply automatic background removal */
    autoRemoveBackground?: boolean;
    imageResolutionConfig?: ImageResolutionValidatorItemConfig;
    assetStore?: VistaAssetStore;
    authToken?: string;
    pageNumber?: number;
}

export interface ImageItemMetadata {
    // Omit ID because we'll add it as part of the add/replace commands
    dclMetadata?: Omit<DclMetadataItem, "id">;
    studioMetadata?: StudioMetadataItem;
}

// This type is more correct than the one exported from assets-sdk as it include values for PDFs
export type ImageInfo = NonNullable<NonNullable<VistaAsset["data"]>["info"]["image"]>;
export type PreparedVistaAsset = VistaAsset & { status: { type: AssetStatusType.Prepared } };
export type VistaAssetWithoutError = VistaAsset & { data: { info: { image: Omit<ImageInfo, "isError"> } } };
export type VistaAssetWithImageInfo = VistaAsset & { data: { info: { image: ImageInfo } } };
export type VistaAssetWithKnownFormat = VistaAsset & {
    data: { info: { image: { format: Exclude<ImageInfo["format"], "unknown"> } } };
};
export type ImageMetadata = Awaited<ReturnType<VistaAsset["getPageImageInfo"]>>;
export type VistaAssetWithImageDimensionInfo = VistaAsset & {
    data: { info: { image: { width: number; height: number } } };
};
export type VistaAssetIsProcessed = PreparedVistaAsset & VistaAssetWithImageInfo;

export type FileParam = { file: File };
export type UriParam = { uri: string };

export type FileOrUriParam = FileParam | UriParam;

export interface UploadErrorParamters {
    asset: VistaAsset | undefined;
    error: string | Error | undefined;
    source: string;
    eventImageInfo?: AssetImageInfoEventDTO;
    trackingClient?: BaseTrackingClient;
}

export interface FieldData {
    field: string;
    analysisField: string;
}

export type ImageUrls = {
    preview: string;
    print: string;
};

export type GetSharpenedUrls = (getSharpenedUrlsConfig: {
    originalUrl: string;
    accessToken: string;
    vistaAssetStore: VistaAssetStore;
}) => Promise<ImageUrls>;

export type Crispify = (crispifyConfig: { imageUrl: string; accessToken: string }) => Promise<string>;

export type BackgroundRemove = (backgroundRemoveConfig: { imageUrl: string; accessToken: string }) => Promise<string>;

export type GetBackgroundRemovedUrls = (getBackgroundRemovedUrlsConfig: {
    originalUrl: string;
    accessToken: string;
    vistaAssetStore: VistaAssetStore;
}) => Promise<ImageUrls>;

export type ModifiedAssetInfo = {
    printUrl?: string;
    previewUrl?: string;
    modifiedAsset?: VistaAsset;
};

export type ModificationType = "backgroundRemove" | "sharpen";
