import type { StudioDesignExperienceExtension } from "@internal/utils-studio-configuration-core";

const shouldShowDigitalExperience: StudioDesignExperienceExtension = {
    shouldShowDigitalExperience: [
        {
            conditions: {
                value: true
            },
            overrides: {
                showChangeSize: false,
                showChangeTemplate: true,
                disableViewControls: true,
                shouldShowGraphics: true
            }
        }
    ]
};

export default shouldShowDigitalExperience;
