import { VistaAsset, VistaAssetStore, VariantType } from "@design-stack-vista/vista-assets-sdk";

// Retrieve an image variant quicker then .getVariant by skipping image info load.  Useful if you only need urls.
export const getImageVariant = async (
    asset: VistaAsset,
    variantType: VariantType,
    assetStore: VistaAssetStore
): Promise<VistaAsset> => {
    const metadata = await asset.getVariantMetadata(asset.data!, variantType);
    const newAsset = await assetStore.fetchSingleAsset({ id: metadata.id });
    await newAsset.presign();
    return newAsset;
};
