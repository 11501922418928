import { ImageResolutionValidatorItemConfig, ImageResolutionStatus } from "../types";

/**
 * getImageResolutionStatus returns status based on this logic \
 * ERROR <= errorTreshold < WARNING <= warningThreshold < OK
 */
export function getImageResolutionStatus(ppi: number, config: ImageResolutionValidatorItemConfig) {
    const isPpiAboveWarningTreshold = ppi > config.minimumPpi * config.warningThreshold;
    if (isPpiAboveWarningTreshold) {
        return ImageResolutionStatus.OK;
    }
    const isPpiEqOrBelowErrorTreshold = ppi <= config.minimumPpi * config.errorThreshold;
    return isPpiEqOrBelowErrorTreshold ? ImageResolutionStatus.ERROR : ImageResolutionStatus.WARNING;
}
