import React, { StrictMode, useMemo, useState } from "react";
import { SwanProvider, ScreenClassProvider } from "@vp/swan";
import { IdentityProvider } from "@design-stack-vista/identity-provider";
import { isProd, isCareAgent, getQueryParams, windowExists } from "@internal/utils-browser";
import { PageContextProvider, PageContextProps, HeadCollection } from "@shared/features/StudioConfiguration";
import { DexName, hideLoader, setLoadingMessage, Store } from "@shared/redux";
import { Provider, useDispatch } from "react-redux";
import { TrackingClient, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { TrackEventsProvider } from "@shared/features/Tracking";
import { TrackingClientProvider } from "@internal/utils-tracking";
import { I18nextProvider } from "@vp/i18n-helper";
import { ProductLoadingProviderWrapper } from "@six/features/ProductLoading/ProductLoadingProviderWrapper";
import { initializeI18n } from "@shared/utils/i18n";
import { FullScreenLoader } from "@shared/features/Loading";
import { StudioFlexibilityABProvider } from "@internal/ab-test-studio-flexibility";
import { SimLocaleProvider } from "@internal/sim-framework";
// @note Sim import violation with be addressed in https://vistaprint.atlassian.net/browse/DJ-451
// eslint-disable-next-line no-restricted-imports
import { SimTeamsNameProvider } from "@internal/advanced-editor-sim-teams-name";
import { ErrorProvider } from "@internal/utils-errors";
import { SimProviderWrapper } from "@six/features/sims";
import { ContentDiscoveryZoneProviderWrapper } from "@six/features/ContentDiscoveryZone";
import { InstantUploadStoreProvider } from "@internal/utils-assets";
import { EmbroideryProvider } from "@six/features/Embroidery";
import { ActiveDialogProvider } from "@internal/utils-active-dialog";
import { useStudioConfigurationManager } from "@internal/dex";
import { observer } from "mobx-react-lite";
import { ChangeSizeButtonABProvider } from "@internal/ab-test-change-size-button";
import { ResizeNoDesignVariationsABProvider } from "@internal/ab-test-resize-no-design-variations";
import { ResizeUsabilityMobileLayoutABProvider } from "@internal/ab-test-resize-usability-mobile-layout";
import { ChangeSizeButtonCopyMobileABProvider } from "@internal/ab-test-change-size-button-copy-mobile";
import { ChangeSizeButtonCopyDesktopABProvider } from "@internal/ab-test-change-size-button-copy-desktop";
import { ChangeSizeButtonPlacementMobileABProvider } from "@internal/ab-test-change-size-button-placement-mobile";
import { ChangeSizeButtonPlacementDesktopABProvider } from "@internal/ab-test-change-size-button-placement-desktop";
import { NewLockedStateABProvider } from "@internal/ab-test-new-locked-state";
import { CanvasTextEditingABProvider } from "@internal/ab-test-canvas-text-editing";
import { EmptyTextABProvider } from "@internal/ab-test-empty-text";
import { TemplateConversionABProvider } from "@internal/ab-test-template-conversion";
import { InlineCroppingABProvider } from "@internal/ab-test-inline-cropping";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { CartContextProviderWrapper } from "@six/features/Cart/CartContextProviderWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useDesignAttributeMappings } from "@shared/features/StudioBootstrap";
import { DesignReviewAnatomyABProvider } from "@internal/ab-test-design-review-anatomy";
import { ChangeSizeClickableRulerABProvider } from "@internal/ab-test-change-size-clickable-ruler";
import { ToastProvider } from "@internal/feature-toasts";
import { DownloadDialogProvider } from "@six/experiments/digital/DownloadFlow/DownloadFlowProvider";
import { Studio6SiteContent } from "./Studio6SiteContent";
import { AbTestContextProviderWrapper } from "./AbTestContextProviderWrapper";

interface DefaultExperienceWrapperProps {
    pageContext?: PageContextProps;
}

type ComponentWithPropsPair<C extends React.ComponentType> = [component: C, React.ComponentProps<C>];

// most of our queries are cached for a while anyway so 5 minutes seems like a safe and performant default
const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 5 * 60 * 1000 } } });

export const DefaultExperienceWrapper = observer((props: React.PropsWithChildren<DefaultExperienceWrapperProps>) => {
    const { children, pageContext } = props;
    const { locale = "en-ie", useProdSwanUrls, ...context } = pageContext || {};

    const [i18nInstance] = useState(() =>
        initializeI18n(locale.toLowerCase(), context.studioTranslations, context.simTranslations)
    );

    const productAndProjectStateManager = useProductAndProjectStateManager();

    const trackingClient = useMemo(() => new TrackingClient(DexName.Studio6), []);

    const ownerId = getQueryParams().owner;

    // store the locale in manager now that it's been removed from pageContext
    if (productAndProjectStateManager.data.locale === "") {
        productAndProjectStateManager.updateConfiguration({ locale });
    }

    // In non-prod we'll have swan load from their cdn
    // bypassing vista-prod's cloudflare and possible bot blocking.  Hopefully our tests will be less flaky.
    const swanProps = useMemo(
        () => (useProdSwanUrls ? { swanTenant: "vistaprint", swanLocale: locale } : {}),
        [useProdSwanUrls, locale]
    );

    const { useProductOptionTools } = useStudioConfigurationManager().data;

    /**
     * @note these providers are ordered such that the first element is the outermost provider
     * and the last element is the innermost provider.
     * The .reduceRight function handles nesting the providers correctly.
     */

    const providers = [
        /**
         * @note the `as ComponentWithPropsPair<typeof Component>` adds a type assertion to verify
         * each component is passed the correct props.
         */
        [QueryClientProvider, { client: queryClient }] as ComponentWithPropsPair<typeof QueryClientProvider>,
        [I18nextProvider, { i18n: i18nInstance }] as ComponentWithPropsPair<typeof I18nextProvider>,
        [SwanProvider, swanProps] as ComponentWithPropsPair<typeof SwanProvider>,
        [Provider, { store: Store }] as ComponentWithPropsPair<typeof Provider>,
        [HeadCollection, { locale, UILibraryDomain: context.UILibraryDomain }] as ComponentWithPropsPair<
            typeof HeadCollection
        >,
        [IdentityProvider, { locale, isProd: isProd(), isCareAgent: isCareAgent(), ownerId }] as ComponentWithPropsPair<
            typeof IdentityProvider
        >,
        [PageContextProvider, { content: context }] as ComponentWithPropsPair<typeof PageContextProvider>,
        [ScreenClassProvider, {}] as ComponentWithPropsPair<typeof ScreenClassProvider>,
        [CartContextProviderWrapper, {}] as ComponentWithPropsPair<typeof CartContextProviderWrapper>,
        [AbTestContextProviderWrapper, { locale }] as ComponentWithPropsPair<typeof AbTestContextProviderWrapper>,
        [TrackEventsProvider, { fireEventTracker: fireDesignToolTrackingEvent }] as ComponentWithPropsPair<
            typeof TrackEventsProvider
        >,
        [TrackingClientProvider, { trackingClient }] as ComponentWithPropsPair<typeof TrackingClientProvider>,
        [ErrorProvider, { useDispatch, hideLoader, setLoadingMessage }] as ComponentWithPropsPair<typeof ErrorProvider>,
        [Studio6SiteContent, {}] as ComponentWithPropsPair<typeof Studio6SiteContent>,
        [StudioFlexibilityABProvider, { useProductOptionTools }] as ComponentWithPropsPair<
            typeof StudioFlexibilityABProvider
        >,
        [ChangeSizeButtonCopyMobileABProvider, {}] as ComponentWithPropsPair<
            typeof ChangeSizeButtonCopyMobileABProvider
        >,
        [ChangeSizeButtonCopyDesktopABProvider, {}] as ComponentWithPropsPair<
            typeof ChangeSizeButtonCopyDesktopABProvider
        >,
        [ChangeSizeButtonPlacementMobileABProvider, {}] as ComponentWithPropsPair<
            typeof ChangeSizeButtonPlacementMobileABProvider
        >,
        [ChangeSizeButtonPlacementDesktopABProvider, {}] as ComponentWithPropsPair<
            typeof ChangeSizeButtonPlacementMobileABProvider
        >,
        [SimLocaleProvider, { locale }] as ComponentWithPropsPair<typeof SimLocaleProvider>,
        [SimTeamsNameProvider, { useDesignAttributeMappings }] as ComponentWithPropsPair<typeof SimTeamsNameProvider>,
        [ContentDiscoveryZoneProviderWrapper, {}] as ComponentWithPropsPair<typeof ContentDiscoveryZoneProviderWrapper>,
        [SimProviderWrapper, {}] as ComponentWithPropsPair<typeof SimProviderWrapper>,
        [ProductLoadingProviderWrapper, {}] as ComponentWithPropsPair<typeof ProductLoadingProviderWrapper>,
        [InstantUploadStoreProvider, {}] as ComponentWithPropsPair<typeof InstantUploadStoreProvider>,
        [EmbroideryProvider, {}] as ComponentWithPropsPair<typeof EmbroideryProvider>,
        [ActiveDialogProvider, {}] as ComponentWithPropsPair<typeof ActiveDialogProvider>,
        [ChangeSizeButtonABProvider, {}] as ComponentWithPropsPair<typeof ChangeSizeButtonABProvider>,
        [ResizeNoDesignVariationsABProvider, {}] as ComponentWithPropsPair<typeof ResizeNoDesignVariationsABProvider>,
        [ResizeUsabilityMobileLayoutABProvider, {}] as ComponentWithPropsPair<
            typeof ResizeUsabilityMobileLayoutABProvider
        >,
        [NewLockedStateABProvider, {}] as ComponentWithPropsPair<typeof NewLockedStateABProvider>,
        [CanvasTextEditingABProvider, {}] as ComponentWithPropsPair<typeof CanvasTextEditingABProvider>,
        [EmptyTextABProvider, {}] as ComponentWithPropsPair<typeof EmptyTextABProvider>,
        [TemplateConversionABProvider, {}] as ComponentWithPropsPair<typeof TemplateConversionABProvider>,
        [DesignReviewAnatomyABProvider, {}] as ComponentWithPropsPair<typeof DesignReviewAnatomyABProvider>,
        [InlineCroppingABProvider, {}] as ComponentWithPropsPair<typeof InlineCroppingABProvider>,
        [ChangeSizeClickableRulerABProvider, {}] as ComponentWithPropsPair<typeof ChangeSizeClickableRulerABProvider>,
        [ToastProvider, { helpCenterUrl: context.helpCenterUrl }] as ComponentWithPropsPair<typeof ToastProvider>,
        [DownloadDialogProvider, {}] as ComponentWithPropsPair<typeof DownloadDialogProvider>
    ] as const;

    const result = providers.reduceRight(
        <C extends React.ComponentType, P extends {} = React.ComponentProps<C>>(
            children: React.ReactNode,
            [Component, props]: [C, P]
        ) => {
            return React.createElement(Component, props, children);
        },
        <>
            <FullScreenLoader />
            {children}
            {windowExists() && !window.Cypress && <ReactQueryDevtools />}
        </>
    );

    return <StrictMode>{result}</StrictMode>;
});

DefaultExperienceWrapper.displayName = "DefaultExperienceWrapper";
