// `structuredClone` is not yet supported in Safari 15.0; see https://vistaprint.atlassian.net/wiki/spaces/NTEO/pages/60132385/Vistaprint+browser+support+
// eslint-disable-next-line
import cloneDeep from "lodash/cloneDeep";
import { useCallback } from "react";
import { DesignTileCallback } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@internal/data-access-design-specifications-service";
import { useErrors } from "@internal/utils-errors";
import { DSS } from "@vp/types-ddif";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";
import { useGetDuplicatePanelTransformations } from "./useGetPreviewWithTransformations";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

function addShapeMetadata(existingDocument: DSS.DesignDocument): DSS.DesignDocument {
    const newDocument = cloneDeep(existingDocument);

    // When DSS duplicates the shapes on a panel, it will also include any field we add to the shape.
    // Add a metadata field to each existing shape that points to it's id. When the duplicated shapes are created,
    // they will also have this field that we can then use to find the correct studioMetadata entry
    for (const panel of newDocument.document.panels) {
        panel.shapes = panel.shapes?.map(shape => ({
            ...shape,
            metadata: {
                ...shape.metadata,
                originalId: shape.id
            }
        }));
    }

    return newDocument;
}

function curryGetModifiedDocument(getDocument: () => Promise<DSS.DesignDocument>): () => Promise<DSS.DesignDocument> {
    return async () => {
        const existingDocument = await getDocument();
        const modifiedExistingDocument = addShapeMetadata(existingDocument);

        return modifiedExistingDocument;
    };
}

export const useSelectDuplicateCallback = () => {
    const { getDocument, loadNewDesign, fireInteractionTimedEvent } = useDesignDialog();
    const transformations = useGetDuplicatePanelTransformations();
    const getPanelSourceExtension = useGetPanelSourceExtension();
    const { handleError } = useErrors();
    const productAndProjectState = useProductAndProjectStateManager();
    const designEngine = useAvailableDesignEngine();
    const frontPanelId = designEngine?.cimDocStore.panels[0].id;

    /**
     * This function is a callback that when called, will duplicate the front panel to  the panel with panelName.
     * @param panelName - panel to receive the duplicated front
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) in essence reduplicating the front, in this case
     * @returns callback
     */
    return useCallback<DesignTileCallback>(
        ({ panelName, resetting }) => {
            return async (dispatch: any, getState: any) => {
                const panelSourceExtension = getPanelSourceExtension(panelName);
                const getModifiedDocument = curryGetModifiedDocument(getDocument);

                if (!panelSourceExtension || !frontPanelId) {
                    return undefined;
                }

                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];

                return applyDesignOption({
                    dispatch,
                    productAndProjectState: productAndProjectState.data,
                    updateProductAndProjectStateConfiguration: productAndProjectState.updateConfiguration,
                    panelName,
                    resetting,
                    newDocumentSourceType: documentSourceTypeMap.DUPLICATE,
                    newDocumentSourceData: frontPanelId,
                    transformations,
                    newOptions: surfaceUpsell
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.colorOption
                          }
                        : undefined,
                    fireInteractionTimedEvent,
                    getDocument: getModifiedDocument,
                    panelSourceExtension,
                    loadNewDesign,
                    handleError
                });
            };
        },
        [
            getPanelSourceExtension,
            getDocument,
            productAndProjectState.data,
            productAndProjectState.updateConfiguration,
            transformations,
            fireInteractionTimedEvent,
            frontPanelId,
            loadNewDesign,
            handleError
        ]
    );
};
